import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../lib/helpers'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain, Backdrop } from '../../containers'
import Hero from '../../components/hero'
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import NumberFormat from 'react-number-format';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import { MdHelpOutline, MdHelp } from "react-icons/md";
import ReactTooltip from 'react-tooltip';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import houseHackingImg from '../../assets/house-hacking-2x2.png'
import Sidebar from '../../components/Sidebar'
import { GraphDesktop, GraphMobile, AxisLabel } from '../../components/GraphComponents'
import { ThemedAccordion, FinancesTableWrapper, FinancesTableGrid, FinancesTableRow } from '../../components/CustomAccordion'
import { GumroadAd } from '../../components/GumroadAd'

const H2 = styled.h2`
  font-weight: 300;
`

const CalculatorPanel = styled.div`
  margin: 0 auto;
  /* padding: 24px; */
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  max-width: 1200px;

  @media (max-width: 500px) {
    border: none;
    border-radius: 0px;
  }
`

const CalculatorGrid = styled.div`
  padding: 24px;
  /* margin: 0 auto;
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px; */
  display: grid;
  /* grid-template-columns: minmax(300px, 1fr) 3fr; */
  grid-template-columns: 1fr;
  grid-gap: 48px;
  align-items: start;
  /* max-width: 1200px; */

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 24px 24px 0 24px;
    border: none;
    border-radius: 0px;
    margin: 0;
  }

  p, ul {
    font-size: 0.9rem;
    font-family: 'Inter', sans-serif;
  }
`

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0 0 0px 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }
  
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }
`

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover{
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }
`

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${props => props.theme.theme.colors.green};
  }
`

const Label = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`

const GroupLabel = styled.h3`
  display: block;
  width: 100%;
  color: ${(props) => props.theme.theme.text.primary};
  text-transform: uppercase;
  margin: 0 0 24px 0;
  font-weight: 500;
  font-size: 0.9rem;
`

const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`

const TooltipPanel = styled.div`
  background-color: ${props => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0,0,0,0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${ props => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: start;
`

const FlexInput = styled.div`
  margin: 0px 12px 12px 0;
  width: 100%;
`;

const InputItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-gap: 4px;
  align-items: center;
  margin: 0 0 0 0;
`;

const AllocationGroup = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 12px 12px 12px;
  margin: 0 0 12px 0;
  width: 100%;

  h3 {
    text-transform: uppercase;
    margin: 0 0 8px 0;
    font-weight: 500;
  }
`;

const AllocationGroupGray = styled(AllocationGroup)`
  border: 1px solid ${(props) => props.theme.theme.border.tertiary};
  color: ${(props) => props.theme.theme.text.primary};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.text.primary};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.border.tertiary};
  }
`;

const AllocationGroupRowTwoCol = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 1fr) 60px;
  grid-gap: 18px;
`;

const AllocationGroupRed = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentRed};
  border: 1px solid ${(props) => props.theme.theme.colors.red};
  color: ${(props) => props.theme.theme.colors.red};
  /* margin: 8px 8px 0 8px; */

  .result-value {
    font-weight: 500;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.red};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.red};
  }
`;

const AllocationGroupGreen = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  /* color: ${(props) => props.theme.theme.colors.green}; */
  /* margin: 8px 8px 0 8px; */

  .result-value {
    font-weight: 500;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.green};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.green};
  }
`;

const SliderBlack = styled(Slider)`
  &.MuiSlider-root {
    color: ${(props) => props.theme.theme.colors.black};
  }
`;

const InputGroup = styled.div`
  box-sizing: border-box;
  display: grid;
  align-content: start;
  /* display: flex; */
  flex-wrap: wrap;
  /* padding: 8px 8px 0 8px; */
  /* border-radius: 8px; */
  /* padding: 12px 12px 0px 12px; */
  /* border: 1px solid ${(props) => props.theme.theme.border.tertiary}; */
  grid-template-columns: 1fr;
  /* grid-gap: 24px; */
  margin: 0 0 0px 0;

  /* h3 {
    color: ${(props) => props.theme.theme.text.primary};
    text-transform: uppercase;
    margin: 0 0 12px 0;
    font-weight: 500;
  } */

  @media (max-width: 500px) {
    /* padding: 24px 0px 0 0px; */
  }
`;

const CustomReactTooltip = styled(ReactTooltip)`
  max-width: 50vw;
`;

const ResultsPanel = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  padding: 12px;
  display: flex;
  flex-wrap: wrap;

  .label {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 8px 0 0;
  }

  .result-value {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 4px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 12px 0 0;
  }

  h3 {
    color: ${(props) => props.theme.theme.colors.green};
  }

  @media (max-width: 500px) {
    margin: 0px;
  }
`;

const ResultsGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;

  @media (max-width: 500px) {
  }
`;

const ColumnsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) ) ;
  grid-gap: 24px;
`

const ContainerContent = styled.div`
  max-width: ${props => props.theme.theme.contentWidths.content};
`

const ContainerTwoCol = styled(ContainerMain)`
  display: grid;
  grid-template-columns: ${props => props.theme.theme.contentWidths.content} 1fr;
  grid-gap: 48px;
  padding-right: 0px;
  align-items: start;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    padding-right: 24px;
  }
`

const Image = styled.img`
  width: 100%;
`

const CustomTooltip = (props) => {
  const {active} = props

  if(active){
    const {payload, label } = props
    console.log(payload)
    console.log(label)

    return(
      <TooltipPanel>
        {/* <CardText300>{label}</CardText300> */}
        <CardText400>
          <strong>Month #: </strong>
          {payload[0].payload.month}
        </CardText400>

        <CardText400>
          <strong>Monthly payment: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.monthlyPayment.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Payment towards interest: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.interestPortion.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Payment towards principal: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.principalPortion.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Loan Balance: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.loanBalance.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
      </TooltipPanel>
    )
  }
  return(null)

}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const HouseHackingCalc = () => {

  const [ interestRate, setInterestRate ] = useState(3.5)
  const [ timeLength, setTimeLength ] = useState(30)
  const [ purchasePrice, setPurchasePrice ] = useState(200000)
  const [ downPayment, setDownPayment ] = useState(40000)
  // const [ principal, setPrincipal ] = useState(50000)
  const [ annualCompoundingPeriods, setAnnualCompoundingPeriods ] = useState(12)
  const [ annualPaymentPeriods, setAnnualPaymentPeriods ] = useState(12)
  const [ monthlyRentIncome, setMonthlyRentIncome ] = useState(1000)
  const [ propertyTaxRate, setPropertyTaxRate ] = useState(1)
  const [ annualHomeInsurance, setAnnualHomeInsurance ] = useState(1400)
  const [ annualMaintenance, setAnnualMaintenance ] = useState(2000)
  const [ vacancyRate, setVacancyRate ] = useState(7)
  const [ rentSavings, setRentSavings ] = useState(1000)

  const principal = purchasePrice - downPayment

  const monthlyPropertyTax = (propertyTaxRate / 100) * (1/12) * purchasePrice
  const monthlyExpenses = monthlyPropertyTax + (annualHomeInsurance/12) + (annualMaintenance/12)
  const averageMonthlyIncome = (monthlyRentIncome*(1- (vacancyRate/100)))

  const rate = (Math.pow( 1 + ((interestRate/100)/annualCompoundingPeriods), annualCompoundingPeriods/annualPaymentPeriods )) - 1
  const nper = annualPaymentPeriods * timeLength
  const payment = principal * ( ( rate * Math.pow( 1 + rate, nper) ) / ( Math.pow( 1 + rate, nper) - 1) )

  const monthlyCashFlow = averageMonthlyIncome - payment - monthlyExpenses

  const calcLoanData = () => {
    let loanData = []
    for(let i = 0; i <= nper; i++){

      if(!i){

        const paymentInterest = principal * rate
        const paymentPrincipal = payment - paymentInterest

        loanData.push({
          month: i,
          monthlyPayment: 0,
          interestPortion: 0,
          principalPortion: 0,
          loanBalance: parseFloat(principal),
          totalInterest: 0
        })
      } else {
        if(i < nper){
          const paymentInterest = loanData[i-1].loanBalance * rate
          const paymentPrincipal = parseFloat(payment) - parseFloat(paymentInterest)

          loanData.push({
            month: i,
            monthlyPayment: payment,
            interestPortion: paymentInterest,
            principalPortion: paymentPrincipal,
            loanBalance: parseFloat(loanData[i-1].loanBalance) - paymentPrincipal,
            totalInterest: loanData[i-1].totalInterest + paymentInterest
          })
        } else {
          const paymentInterest = loanData[i-1].loanBalance * rate
          const paymentPrincipal = payment - paymentInterest

          loanData.push({
            month: i,
            monthlyPayment: payment,
            interestPortion: paymentInterest,
            principalPortion: paymentPrincipal,
            loanBalance: 0,
            totalInterest: loanData[i-1].totalInterest + paymentInterest
          })
        }

      }
    }
    return loanData
  }

  const loanData = calcLoanData()

  // const netWorthImpact = 100
  const netWorthImpact = parseFloat(monthlyCashFlow)  + parseFloat(rentSavings) + loanData[1].principalPortion
  console.log(netWorthImpact)


  console.log(loanData)

  return (
    <Layout>
      <SEO
        title={'House Hacking Calculator'}
        description={'Accelerate your path to financial freedom by being a live-in landlord'}
      />
      <CustomReactTooltip />

      <CalculatorPanel>
        <CalculatorGrid>
          <form>
          <ColumnsWrapper>
            <InputGroup>
              <GroupLabel>Mortgage</GroupLabel>
              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  required
                  id="outlined-required"
                  label="Home Purchase Price"
                  value={purchasePrice}
                  onChange={event => setPurchasePrice(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the purchase price of your property.`}
                />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  required
                  id="outlined-required"
                  label="Down Payment"
                  value={downPayment}
                  onChange={event => setDownPayment(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the down payment you make on your home purchase. Down payments on owner-occupied homes
                  are typically between 3.5% and 20%.`}
                />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                    required
                    id="outlined-required"
                    label="Length of Loan (in Years)"
                    value={timeLength}
                    onChange={event => setTimeLength(event.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                  <MdHelp
                    size="1.2rem"
                    data-tip={`This is the length in years over which you will pay back your loan. Most home loans are 15 or 30 year loans.`}
                  />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                    required
                    id="outlined-required"
                    label="Interest Rate"
                    value={interestRate}
                    onChange={event => setInterestRate(event.target.value)}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatPercentage,
                    }}
                  />
                  <MdHelp
                    size="1.2rem"
                    data-tip={`This is the interest rate of your loan.`}
                  />
                </InputItem>
              </FlexInput>
            </InputGroup>

            <InputGroup>
              <GroupLabel>Additional Expenses</GroupLabel>
              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  required
                  id="outlined-required"
                  label="Property Tax Rate"
                  value={propertyTaxRate}
                  onChange={event => setPropertyTaxRate(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`Your home's property tax rate varies by location and can range from around 0.28% to nearly 2.5%.`}
                />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "275px" }}>
                <InputItem>
                  <ThemedTextarea
                  required
                  id="outlined-required"
                  label="Annual Homeowners Insurance"
                  value={annualHomeInsurance}
                  onChange={event => setAnnualHomeInsurance(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`The annual cost of homeowners insurance in the USA is about $1400, but it varies by state and home value.`}
                />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  required
                  id="outlined-required"
                  label="Annual Maintenance Costs"
                  value={annualMaintenance}
                  onChange={event => setAnnualMaintenance(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`Homeowners spend an average of $1,100 annually on maintenance. A popular rule is to budget 1% of your
                  home's purchase price for ongoing maintenance, or $1 per square foot. Your maintenance costs can vary depending on the age of your home.`}
                />
                </InputItem>
              </FlexInput>
            </InputGroup>

            <InputGroup>
              <GroupLabel>Rent Income</GroupLabel>

              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  required
                  id="outlined-required"
                  label="Rent Income (Monthly)"
                  value={monthlyRentIncome}
                  onChange={event => setMonthlyRentIncome(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the monthly income that you expect to earn from renting out part of your property.`}
                />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  required
                  id="outlined-required"
                  label="Vacancy Rate"
                  value={vacancyRate}
                  onChange={event => setVacancyRate(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the percentage of time that you expect your unit(s) to be unoccupied.
                  The average vacancy rate is the USA is about 7% but varies by city.`}
                />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  required
                  id="outlined-required"
                  label="Rent Savings (per month)"
                  value={rentSavings}
                  onChange={event => setRentSavings(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the amount that you would pay in rent if you did not house-hack.`}
                />
                </InputItem>
              </FlexInput>

            </InputGroup>



            {/* <ResultsPanel>
              <GroupLabel>Monthly</GroupLabel>
              <ResultsGroup>
                <span className="label">Mortgage payment (Principal and Interest): </span>
                <span className="result-value">
                  <NumberFormat
                    displayType={"text"}
                    value={payment.toFixed(2)}
                    thousandSeparator
                    prefix="$"
                  />
                </span>
              </ResultsGroup>

              <ResultsGroup>
                <span className="label">Total interest: </span>
                <span className="result-value">
                  <NumberFormat
                      displayType={"text"}
                      value={loanData[nper].totalInterest.toFixed(2)}
                      thousandSeparator
                      prefix="$"
                  />
                </span>
              </ResultsGroup>
            </ResultsPanel> */}
          </ColumnsWrapper>

          <ColumnsWrapper>
            <AllocationGroupRed>
              <Label>
                Principal and Interest
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is your monthly mortgage payment, which includes the principal you are paying down and interest.`}
                />
              </Label>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={payment.toFixed(2)}
                  thousandSeparator
                  prefix="$"
                  suffix=" /month"
                />
              </span>
            </AllocationGroupRed>

            <AllocationGroupRed>
              <Label>
                Additional Expenses
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is your total monthly expenses not including your mortgage payment.`}
                />
              </Label>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={monthlyExpenses.toFixed(2)}
                  thousandSeparator
                  prefix="$"
                  suffix=" /month"
                />
              </span>
            </AllocationGroupRed>

            <AllocationGroupGreen>
              <Label>
                Average income
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is your expected rental income adjusted for vacancy.`}
                />
              </Label>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={averageMonthlyIncome.toFixed(2)}
                  thousandSeparator
                  prefix="$"
                  suffix=" /month"
                />
              </span>
            </AllocationGroupGreen>

            <AllocationGroupGray>
              <Label>
                Net cash flow
                <MdHelp
                  size="1.2rem"
                  data-tip={`(Net cash flow) = (average income) - (principal & interest) - (additional expenses).
                  This is your monthly cash flow after you have covered all expenses. It is OK for this to be negative!`}
                />
              </Label>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={(monthlyCashFlow).toFixed(2)}
                  thousandSeparator
                  prefix="$"
                  suffix=" /month"
                />
              </span>
            </AllocationGroupGray>

            <AllocationGroupGray>
              <Label>
                Principle Paydown
                <MdHelp
                  size="1.2rem"
                  data-tip={`(This is the amount of your first mortgage payment that is going towards your home equity (or principle).
                  Note that as you get closer to paying off your mortgage, an increasing portion of your mortgage payment goes towards
                  principle (see Loan Amortization Schedule below).`}
                />
              </Label>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={(loanData[1].principalPortion).toFixed(2)}
                  thousandSeparator
                  prefix="$"
                  suffix=" /month"
                />
              </span>
            </AllocationGroupGray>

            <AllocationGroupGray>
              <Label>
                Overall Net Worth Impact
                <MdHelp
                  size="1.2rem"
                  data-tip={`(Overall net worth impact) = (net cash flow) + (principle paydown) + (rent savings).
                  This is the total monthly impact that house hacking is having on your net worth compared to if
                  you were to rent instead. Remember, by house hacking you no longer have to pay rent, and instead you use
                  rent income from tenants to help cover your mortgage and build home equity.`}
                />
              </Label>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={(netWorthImpact).toFixed(2)}
                  thousandSeparator
                  prefix="$"
                  suffix=" /month"
                />
              </span>
            </AllocationGroupGray>

          </ColumnsWrapper>

          </form>

            {/* <GraphDesktop>
              <ResponsiveContainer width='100%' height={500} >
                <AreaChart data={loanData}
                    margin={{top: 10, right: 0, left: 50, bottom: 30}}>
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis dataKey="month" label={{value: "Time (months)", position: "insideBottom", offset: -10}} />
                  <YAxis
                    tickFormatter={tick => {
                      return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    }}
                    yAxisId="left"
                  />
                  <YAxis
                    tickFormatter={tick => {
                      return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    }}
                    yAxisId="right"
                    orientation="right"
                  />
                  <Tooltip
                    content={<CustomTooltip/>}
                  />
                  <Legend verticalAlign="top" height={50} />
                  <Area type='monotone' name="Loan Balance ($)" dataKey='loanBalance' stroke='#3AC732' fill='#CFF0CD' yAxisId="left" />
                  <Area type='monotone' name="Monthly payment ($)" dataKey='monthlyPayment' stroke='#000' fillOpacity={0} yAxisId="right"/>
                  <Area type='monotone' name="Payment towards interest ($)" dataKey='interestPortion' stroke='#FF1733' fillOpacity={0} yAxisId="right"/>
                  <Area type='monotone' name="Payment towards principal ($)" dataKey='principalPortion' stroke='#1274E0' fillOpacity={0} yAxisId="right"/>
                </AreaChart>
              </ResponsiveContainer>
            </GraphDesktop> */}

            {/* <GraphMobile>
              <ResponsiveContainer width='100%' height={500} >
                <AreaChart data={loanData}
                    margin={{top: 10, right: 0, left: 10, bottom: 30}}>
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis dataKey="month" label={{value: "Time (months)", position: "insideBottom", offset: -10}} />
                  <YAxis
                    tickFormatter={tick => {
                      return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    }}
                    yAxisId="left"
                    mirror
                  />
                  <YAxis
                    tickFormatter={tick => {
                      return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    }}
                    yAxisId="right"
                    orientation="right"
                    mirror
                  />
                  <Tooltip
                    content={<CustomTooltip/>}
                  />
                  <Legend verticalAlign="top" height={110} />
                  <Area type='monotone' name="Loan Balance ($)" dataKey='loanBalance' stroke='#3AC732' fill='#CFF0CD' yAxisId="left" />
                  <Area type='monotone' name="Monthly payment ($)" dataKey='monthlyPayment' stroke='#000' fillOpacity={0} yAxisId="right"/>
                  <Area type='monotone' name="Payment towards interest ($)" dataKey='interestPortion' stroke='#FF1733' fillOpacity={0} yAxisId="right"/>
                  <Area type='monotone' name="Payment towards principal ($)" dataKey='principalPortion' stroke='#1274E0' fillOpacity={0} yAxisId="right"/>
                </AreaChart>
              </ResponsiveContainer>
            </GraphMobile> */}
        </CalculatorGrid>

        <ThemedAccordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>Loan Amortization Schedule</AccordionSummary>
          <AccordionDetails>
            <FinancesTableWrapper>
              <FinancesTableGrid>
                <FinancesTableRow className="labels-row">
                  <span>Month #</span>
                  <span>Payment</span>
                  <span>Interest</span>
                  <span>Principal</span>
                  <span>Loan Balance</span>
                </FinancesTableRow>

                {loanData.map((dataPoint, index) => {
                  if (!index) {
                    return (
                      <FinancesTableRow>
                        <span>{dataPoint.month}</span>
                        <span>{dataPoint.monthlyPayment}</span>
                        <span>{dataPoint.interestPortion}</span>
                        <span>{dataPoint.principalPortion}</span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.loanBalance.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                      </FinancesTableRow>
                    );
                  } else {
                    return (
                      <FinancesTableRow>
                        <span>{dataPoint.month}</span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.monthlyPayment.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.interestPortion.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.principalPortion.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.loanBalance.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                      </FinancesTableRow>
                    );
                  }
                })}
              </FinancesTableGrid>
            </FinancesTableWrapper>
          </AccordionDetails>
        </ThemedAccordion>

      </CalculatorPanel>

    </Layout>
  )
}

export default HouseHackingCalc
